var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.$t('tableHeaders'),"items":_vm.promo_overview_salestable,"items-per-page":5},scopedSlots:_vm._u([{key:"item.impactable_rx",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.numberWithCommas(item.impactable_rx)))])]}},{key:"item.impactable_revenue",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.impactable_revenue)))])]}},{key:"item.tactic_spend",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.tactic_spend)))])]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((((item.roi * 100).toFixed(0)) + "%")))])]}},{key:"item.maximum_responsiveness",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.maximum_responsiveness.toFixed(2)))])]}},{key:"item.current_responsiveness",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.current_responsiveness.toFixed(2)))])]}},{key:"item.contribution_to_rx",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.contribution_to_rx * 100).toFixed(2))+"%")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }