<template>
  <v-data-table
    :headers="$t('tableHeaders')"
    :items="promo_overview_salestable"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:item.impactable_rx="{ item }">
      <span>{{ numberWithCommas(item.impactable_rx) }}</span>
    </template>
    <template v-slot:item.impactable_revenue="{ item }">
      <span>{{ formatCurrency(item.impactable_revenue) }}</span>
    </template>
    <template v-slot:item.tactic_spend="{ item }">
      <span>{{ formatCurrency(item.tactic_spend) }}</span>
    </template>
    <template v-slot:item.roi="{ item }">
      <span>{{ `${(item.roi * 100).toFixed(0)}%` }}</span>
    </template>
    <template v-slot:item.maximum_responsiveness="{ item }">
      <span>{{ item.maximum_responsiveness.toFixed(2) }}</span>
    </template>
    <template v-slot:item.current_responsiveness="{ item }">
      <span>{{ item.current_responsiveness.toFixed(2) }}</span>
    </template>
    <template v-slot:item.contribution_to_rx="{ item }">
      <span>{{ (item.contribution_to_rx * 100).toFixed(2) }}%</span>
    </template>
  </v-data-table>
</template>

<script>
import { formatCurrency, numberWithCommas } from "@/utils";

export default {
  name: "SalesTable",
  computed: {
    promo_overview_salestable() {
      return this.$store.state.results.promo_overview_salestable;
    },
  },
  methods: {
    formatCurrency,
    numberWithCommas,
  },
};
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
